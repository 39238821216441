var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"title":""}},[_c('div',[_c('vue-element-loading',{attrs:{"active":_vm.loading,"color":"#00cca2","spinner":"spinner"}}),_c('ve-line',{attrs:{"legend":{show:false},"judge-width":"","data":_vm.leads_data,"mark-line":_vm.markLine,"grid":{right: '5%'},"toolbox":_vm.toolbox,"height":"450px","title":{
                         text:'跟进统计',
                         subtext: "",
                         textStyle: {
                             fontSize: 15,
                         },
                         subtextStyle: {
                             fontSize: 12.5
                         }
                     },"extend":{
                         yAxis:{
                 splitLine: {
                 lineStyle: {
                     type: 'dotted'
                 }
                 }
             },
             dataZoom:[{type:'inside'}]},"settings":{ 
                     lineStyle: {
                         normal: {
                             width: 3
                         }
                     },
                     areaStyle:{
                         area: true,
                         normal:{
                             color:{
                                 type: 'linear',
                                 x: 0,
                                 y: 0,
                                 x2: 0,
                                 y2: 1,
                                 colorStops: [{
                                     offset: 1, color: '#ffffff' // 0% 处的颜色
                                 }, {
                                     offset: 0, color: '#23d9ae' // 100% 处的颜色
                                 }],
                                 global: false // 缺省为 false
                             },
                             shadowColor: 'rgba(0, 0, 0, 0.1)',
                             shadowBlur: 10
                         }
                     },
                     label: { show: true, position: 'top'}}}}),(_vm.first>0 && _vm.leads_data.rows.length == 0)?_c('LEmpty'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }