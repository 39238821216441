<template>
   <a-card title="">
        <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <ve-line 
                    judge-width
                    :legend="{show:false}"
                    :data="leads_data" :mark-line="markLine" :grid="{right: '5%'}" 
                        :toolbox="toolbox"
                        height="450px"
                        :title="{
                            text:'新增客户统计',
                            subtext: ``,
                            textStyle: {
                                fontSize: 15,
                            },
                            subtextStyle: {
                                fontSize: 12.5
                            }
                        }" 
                        :extend="{
                yAxis:{
                    splitLine: {
                    lineStyle: {
                        type: 'dotted'
                    }
                    }
                },
                dataZoom:[{type:'slider'}]}" 
                        :settings="{ 
                        lineStyle: {
                            normal: {
                                width: 3
                            }
                        },
                        areaStyle:{
                            area: true,
                            normal:{
                                color:{
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 1, color: '#ffffff' // 0% 处的颜色
                                    }, {
                                        offset: 0, color: '#23d9ae' // 100% 处的颜色
                                    }],
                                    global: false // 缺省为 false
                                },
                                shadowColor: 'rgba(0, 0, 0, 0.1)',
                                shadowBlur: 10
                            }
                        },
                        label: { show: true, position: 'top'}}"></ve-line>
                        <LEmpty v-if="first>0 && leads_data.rows.length == 0"/>
            </div>
    </a-card>
</template>

<script>
    export default {
        name:"customer_count",
        data () {
            this.markLine = {
                symbol: ['none', 'none'], //去掉箭头
                data: [
                {
                    name: '平均线',
                    type: 'average'
                }
                ]
            },
            this.toolbox = {
                feature: {
                magicType: {},
                saveAsImage: {pixelRatio:2}
                }
            }
            return{
                first:0
            }
        },
        props:['leads_data' , 'loading'],
        watch: {
            loading(newValue, oldValue) {
                this.first++;
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>