<template>
   <a-card title="">
        <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <ve-ring :data="contact_type_data" :settings="chartSettings"></ve-ring>
            </div>
    </a-card>
</template>

<script>
    export default {
        name:"contact_type",
        data () {
             this.chartSettings = {
                dataType: 'percent'
            },
            this.toolbox = {
                feature: {
                magicType: {},
                saveAsImage: {pixelRatio:2}
                }
            }
            return{
                
            }
        },
        props:['contact_type_data' , 'loading'],
    }
</script>

<style lang="scss" scoped>

</style>