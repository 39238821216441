<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
      </div>
      <div class="search">
        <a-form layout='inline'>
          <a-form-item>
            <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
              <a-radio-button value="day">
                日
              </a-radio-button>
              <a-radio-button value="month">
                月
              </a-radio-button>
              <a-radio-button value="year">
                年
              </a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>时间筛选</span>
              </template>
              <a-range-picker
                :ranges="rangesData"
                :value='rangesValue'
                :disabled-date="disabledDate" :allowClear="false"
                :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                @change="(date, dateString)=>onChange(date, dateString,1)" />
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>上课校区</span>
              </template>
              <a-select allowClear showSearch placeholder="请选择校区" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' :filterOption="filterOption" @change="handleChange" style="width: 220px;">
                <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button @click="toScreen" type="primary">筛选</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>   
    <div class="dashboard bk-group">
       <statistics :count='counts'/>
      
      <a-row class="mb16" :gutter="16">
          <a-col :lg="24" :xl="24">
            <customerCount :loading='loading'  :leads_data ='leads_data'/>
          </a-col>
      </a-row>
    </div>

  </div>
</template>

<script>
import ranges from "@/common/mixins/ranges";
import moment from 'moment'
import customerCount from './customer_count'
import statistics from '@/views/bi/ranking/money/statistics'
export default {
  name: 'customer',
  components:{
    customerCount,
    statistics
  },
  data() {
    return {
      loading:false,
      leads_data:{},
      counts:[],
      studios:[],
      paramsData:{
        start_date:'',
        end_date:'',
        studio_id:'',
        filter_type:'day'
      },
    }
  },
  mixins: [ranges],
  created() {
    this.paramsData.start_date = moment().subtract('days',30).format('YYYY-MM-DD')
    this.paramsData.end_date = moment().subtract('days',0).format('YYYY-MM-DD')
    this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
    this.getList();
    this.getStudio();
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getList(obj){
      this.loading = true
      this.getCount(obj)
      let res = await this.$store.dispatch('analysisCrmCustomerAction', obj)
      this.leads_data = res.data
      this.loading = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getCount(obj){
      let res = await this.$store.dispatch('analysisCrmCustomerCountAction', obj)
      this.counts = res.data
    },
    handleChange(value){
      this.paramsData.studio_id = value;
    },
    handleButChange(e){
      this.paramsData.filter_type = e.target.value
      this.toScreen();
    },
    toScreen(){
      let obj = {
        search:this.paramsData
      }
      this.getList(obj);
    }
  }
}
</script>
