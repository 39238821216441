<template>
    <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>招生统计</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" defaultActiveKey="1">
        <a-tab-pane key="1">
          <span slot="tab">渠道统计</span>
          <clue />
        </a-tab-pane>

        <a-tab-pane key="2">
          <span slot="tab">客户统计</span>
          <customer />
        </a-tab-pane>

        <a-tab-pane key="3">
          <span slot="tab">跟进统计</span>
          <follow />
        </a-tab-pane>

      </a-tabs>
    </div>
  </div>
</template>

<script>
import clue from './clue'
import customer from './customer'
import follow from './follow'

export default {
    name:'CrmAnalysis',
    components:{
      clue,
      customer,
      follow
    }
}
</script>

<style lang="scss" scoped>

</style>